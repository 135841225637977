import React from 'react';
import { Heading, PropertyGroup } from '../../components';

import css from './ListingPage.module.css';

const SectionMultiEnumMaybe = props => {
  const { heading, options, selectedOptions } = props;
  //if (!heading || !options || !selectedOptions) {
  if (!heading || !options || !selectedOptions || !selectedOptions.length > 0) { // CUSTOM: Hide section entirely if no items are selected
    return null;
  }

  const filteredOptions = options.filter(o => selectedOptions.includes(o.key)); // CUSTOM: Prevent unselected values from appearing in list

  return (
    <div className={css.sectionMultiEnum}>
      <Heading as="h2" rootClassName={css.sectionHeading}>
        {heading}
      </Heading>
      <PropertyGroup
        id="ListingPage.amenities"
        //options={options}
        options={filteredOptions}
        selectedOptions={selectedOptions}
        twoColumns={options.length > 5}
      />
    </div>
  );
};

export default SectionMultiEnumMaybe;
